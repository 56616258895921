<template>
  <v-btn class="white rounded-lg parent-store-button" height="327px" width="297px" >
  <span>
    <v-img class="parent-store-image" src="~@/assets/images/logo.svg" max-width="128px" max-height="127px" contain></v-img>
    <p class="text-h4 mt-6 text-capitalize font-weight-regular">Parent Store</p>
    <p class="text-caption text-wrap">I am the source of the truth.</p>
    <p class="text-caption caption-text">I hold the master inventory & product information. I can also fulfill and ship products to the customer.</p>
  </span>
  </v-btn>
</template>

<script>
export default {
  name: "ParentStoreButton"
}
</script>

<style scoped>
.parent-store-button{

}
.parent-store-image{
  margin: 0 60.5px 24px;
}
.caption-text{
  width: 249px;
  white-space: pre-wrap;
}
</style>