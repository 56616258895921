<template>
  <v-container class="container-max-width">
    <v-row class="text-center" v-if="this.isLoading || this.temporaryPageLoad">
      <v-col cols="12">
        <loading-syncio-logo></loading-syncio-logo>
      </v-col>
    </v-row>
    <v-row class="text-center" v-else-if="this.tokenError">
      <v-col cols="12">
        <p class="text-h1">Please try again, Error occurred during installation. Or, contact helpdesk for support.</p>
      </v-col>
    </v-row>
    <v-row class="page-wrapper" v-else>
      <v-col offset="2" cols="8">
        <v-row>
          <v-col cols="12" class="align-center">
            <div class="text-center">
              <svg width="130px" height="30px" viewBox="0 0 65 30">
                <circle cx="10" cy="10" r="9" class="filled"/>
                <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                <circle cx="40" cy="10" r="9" class="not-filled"/>
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row class="heading-text-wrapper">
          <v-col class="primary--text" cols="12">
            <p class="text-h2 ml-5 text-center">What type of store are you?</p>
          </v-col>
        </v-row>
        <v-row class="buttons-wrapper text-center">
          <v-col>
            <ParentStoreButton @click.native="setShopType('source')"/>
          </v-col>
          <v-col>
            <ChildStoreButton @click.native="setShopType('destination')"/>
          </v-col>
        </v-row>
        <v-row class="read-guide">
          <v-col cols="12">
            <p class="text-body-1 text-center">Not sure? Read our guide here.</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ParentStoreButton from "@/views/registration/components/ParentStoreButton";
import ChildStoreButton from "@/views/registration/components/ChildStoreButton";
import { mapGetters } from "vuex";
import {LOGIN_SHOPIFY, REGISTER_SHOP_TYPE, SAVE_SHOPIFY_TOKEN} from "@/store/actions.type";
import LoadingSyncioLogo from "@/views/registration/components/LoadingSyncioLogo";

export default {
  name: "SelectStoreType",
  data() {
    return {
      tokenError: false,
      temporaryPageLoad: false
    }
  },
  components: {LoadingSyncioLogo, ChildStoreButton, ParentStoreButton },
  computed : {
    ...mapGetters("auth", ["isLoading"]),
    ...mapGetters("shop", ["newShop"]),
  },
  created() {
    let payLoad = this.$route.query;
    payLoad.store_id = payLoad.shop;
    this.$store.dispatch(`auth/${SAVE_SHOPIFY_TOKEN}`, payLoad)
        .then((response) => {
          if (response.data.success == false){
            this.tokenError = true;
          }else{
            let storeName = response.data.store_name;
            console.log(storeName);
            this.$store.dispatch(`auth/${LOGIN_SHOPIFY}`, {storeName}).then(() => {
              this.$store.dispatch(`shop/loadNewShop`)
            })
          }
        })
  },
  methods : {
    setShopType(type) {
      this.temporaryPageLoad = true;
      this.$store.dispatch(`shop/${REGISTER_SHOP_TYPE}`, { store_id: this.newShop.id,  type: type }).then(() => {
        this.$router.push({name: 'shopify.select-plan'});
        this.temporaryPageLoad = false;
      })
    }
  }
}
</script>

<style scoped>
.container-max-width{
  max-width: 1200px;
  min-width: 768px !important;
}
.page-wrapper{
  margin-top: 1%;
}
.heading-text-wrapper{
  margin-top: 7%;
}
.buttons-wrapper{
  margin-top: 6%;
  align-content: center;
}
.filled{
  fill: #fcb058;
  stroke-width: 1;
  stroke: #f57c00;
}
.not-filled{
  fill: none;
  stroke-width: 1;
  stroke: #f57c00;
}
.line{
  stroke-width: 2;
  stroke:#f57c00
}
.read-guide{
  margin-top: 6%;
}
</style>