<template>
  <v-btn class="child-store-button white rounded-lg" height="327px" width="297px" >
  <span>
    <v-img class="child-store-image" src="~@/assets/images/logo.svg" max-width="128px" max-height="127px" contain></v-img>
    <p class="text-h4 mt-6 text-capitalize font-weight-regular">Child Store</p>
    <p class="text-caption text-wrap">I listen to Parent Store</p>
    <p class="text-caption caption-text">I sync inventory and product information from the parent store. I can also send orders to the parent store to fulfill.</p>
  </span>
  </v-btn>
</template>

<script>
export default {
  name: "ChildStoreButton"
}
</script>

<style scoped>
.child-store-button {

}
.child-store-image{
  margin: 0 60.5px 24px;
}
.caption-text {
  width: 249px;
  white-space: pre-wrap;
}
</style>